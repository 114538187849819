import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private translate: TranslateService, private toastr: ToastrService) {}

    public error(text: string, options?: Partial<IndividualConfig>): void {
        this.toastr.error(this.translate.instant(text, options || {}), undefined, { timeOut: 10000, enableHtml: true, progressBar: true, positionClass: 'toast-bottom-center' });
    }

    public success(text: string, options?: Partial<IndividualConfig>): void {
        this.toastr.success(this.translate.instant(text, options || {}), undefined, { timeOut: 5000, enableHtml: true, positionClass: 'toast-bottom-center' });
    }

    public warning(text: string, options?: Partial<IndividualConfig>): void {
        this.toastr.warning(this.translate.instant(text, options || {}), undefined, { timeOut: 3000, enableHtml: true, progressBar: true, positionClass: 'toast-bottom-center' });
    }

    public info(text: string, options?: Partial<IndividualConfig>): void {
        this.toastr.info(this.translate.instant(text, options || {}), undefined, { timeOut: 3000, enableHtml: true, positionClass: 'toast-bottom-center' });
    }

    public offline(text?: string, options?: Partial<IndividualConfig>): void {
        if (!text) {
            text = 'OFFLINE_DATA_FETCHED';
        }
        this.toastr.info(this.translate.instant('ERROR_MSG_UNABLE_TO_READ_SERVICE_OFFLINE'), this.translate.instant(text, options || {}), { timeOut: 3000, enableHtml: true, progressBar: true });
    }
}
